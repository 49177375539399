.error-toast-message {
  color: #cc2427;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin: 20px;

  &::before {
    content: url('../../assets/toast-error-icon.svg');
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
}
