.blank-memo-text {
  font-style: italic;
  color: #011728;
  opacity: 0.7;
  width: 70%;
}

.check-confirmation-data {
  word-wrap: break-word;
  display: inline-block;
  width: calc(100% - 42px);
}

.check-confirmation-checkbox-row {
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 16px;
  margin-bottom: 16px;

  .check-confirmation-checkbox-details {
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
  }
}
