.check-form-label {
  padding-bottom: 5px;
}

.check-form-helper {
  margin: 0;
  color: #687680;
}

.field-length-counter {
  font-size: 12px;
  color: #011728;
  align-self: center;
  opacity: 0.4;
  font-weight: 600;
}
