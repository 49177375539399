.qr-content {
  justify-content: space-between;
  height: calc(100% - 118px);

  .qr-check-details {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .qr-transaction-details-row {
      display: flex;
      justify-content: space-between;
      padding: 5px 0px;
    }
  }

  .transaction-details-value {
    word-wrap: break-word;
    float: right;
    font-size: 16px;
    display: block;
    width: 75%;
    text-align: right;
  }

  .qr-image-container {
    text-align: center;
    margin-bottom: 20px;

    .qr-image {
      width: 100%;
      max-width: 400px;
      image-rendering: pixelated;
    }
  }
}
