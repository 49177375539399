.checkbox {
  @extend .grv-checkbox__input;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  + .checkbox-label {
    @extend .grv-checkbox__label;
    padding: 0;
    float: right;
    width: 24px;
  }

  &:focus + .checkbox-label::after {
    outline: 1px solid #255f82;
    box-shadow: 0 0px 8px #255f82;
  }

  + .checkbox-label-left {
    @extend .grv-checkbox__label;
    padding: 0;
    float: left;
    width: 24px;
  }

  &:focus + .checkbox-label-left::after {
    outline: 1px solid #128020;
    box-shadow: 0 0px 8px #128020;
  }
}

.checkbox-label-left::after {
  border-color: #128020 !important;
  background-color: #128020 !important;
}
