.error-container {
  margin: 0 grv-spacing(medium);
  padding-left: grv-spacing(tiny);
  border-left: grv-spacing(tiny); // solid $grv-color__red-50;
  // background-color: $grv-color__red-10;
  color: $grv-color__red-50;
  overflow: hidden;
  transform-origin: top;
  transition: all 0.3s ease;
}

.error-container-prompt {
  @extend .grv-text__body--normal;
}

.error-container-icon {
  width: grv-spacing(medium);
  height: grv-spacing(medium);

  color: $grv-color__red-50;
}
