.landing-header-container {
  margin: grv-spacing(small) auto grv-spacing(tiny) auto;
}

.landing-header-logo-container {
  width: grv-spacing(xxlarge);
  margin: 0 auto grv-spacing(tiny) auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.header {
  position: relative;
  height: 120px;
  overflow: hidden;
}

.header-text {
  width: 100%;
  @extend .grv-text__heading--large;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(2, 2.4) translate(-24px, 32px);
}

.canvas {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  background-color: #efeff1;
  // height: calc(100% - 182px);
}

.content-box {
  background-color: white;
  height: 100%;
  margin: 16px;
  padding: 24px 16px;
}

.landing-page-list {
  list-style: none;
  counter-reset: step-number;
}

.landing-page-list li {
  @extend .grv-text__body--small;
  margin: 24px 0 24px 0;
  counter-increment: step-number;
  position: relative;
  display: flex;
}

.landing-page-list li::before {
  content: counter(step-number) ' ';
  color: white;
  font-weight: bold;
  position: absolute;
  --size: 32px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  background: $grv-color__core-blue-40;
  border-radius: 50%;
  text-align: center;
  align-self: center;
}
