.wizard-header-container {
  display: flex;
  flex-direction: column;
}

.wizard-page-title {
  @extend .grv-text__heading--medium-large;
  text-align: center;
  margin-top: 10px;
}

.action-bar-container {
  display: flex;
  justify-content: space-between;
  height: grv-spacing(medium);
  margin: grv-spacing(tiny);
  margin-bottom: grv-spacing(tiny);
}

.action-bar-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  height: grv-spacing(medium);
  line-height: grv-spacing(medium);
  color: $grv-color__interaction-blue-40;
}

.action-bar-item-icon {
  width: grv-spacing(medium);
  height: grv-spacing(medium);
  color: $grv-color__interaction-blue-40;
}

.cancel-confirmation-container {
  height: 0;
  margin: 0 grv-spacing(medium);
  padding-left: grv-spacing(tiny);
  border-left: grv-spacing(tiny) solid $grv-color__red-50;
  background-color: $grv-color__red-10;
  overflow: hidden;
  transform-origin: top;
  transition: all 0.3s ease;
}

.cancel-confirmation-prompt {
  @extend .grv-text__body--normal;
}

.cancel-buttons-container {
  float: right;
  padding: 5px;
}

.cancel-confirmation-button {
  cursor: pointer;
}
