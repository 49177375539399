.spinner {
  @extend .grv-spinner;
  background: transparent;
  position: fixed;
}

.spinner-active {
  @extend .grv-spinner--active;
}

.spinner::after {
  border: 8px solid #255f82;
  height: 128px;
  width: 128px;
  border-radius: 50%;
  border-top-color: transparent;
  box-sizing: border-box;
  content: '';
  display: block;
  opacity: 1;
  transform-origin: 50% 50%;
  transform: scale(1);
}

.spinner.small::after {
  border: 4px solid #255f82;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-top-color: transparent;
  box-sizing: border-box;
  content: '';
  display: block;
  opacity: 1;
  transform-origin: 50% 50%;
  transform: scale(1);
}
