.caution-icon {
  width: grv-spacing(xxlarge) !important;
  height: grv-spacing(xxlarge) !important;
  color: $grv-color__yellow-50;
  background-color: #273b49;
  border-radius: 25%;
}

.check-mark-icon {
  width: grv-spacing(xxlarge);
  height: grv-spacing(xxlarge);
  filter: invert(55%) sepia(56%) saturate(481%) hue-rotate(62deg) brightness(92%) contrast(82%);
}
