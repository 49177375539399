* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  font-family: Optimist, Helvetica, Arial, sans-serif;
  overscroll-behavior: none;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

.content-container {
  display: flex;
  flex-flow: column;
  margin: 0 grv-spacing(medium);
  box-sizing: border-box;
}

.content-title {
  @extend .grv-text__heading--medium;
  margin-bottom: grv-spacing(small);
}

.normal-margin-bottom {
  margin-bottom: grv-spacing(normal);
}

.form-field-label {
  @extend .grv-text__body--normal;
  margin-bottom: 4px;
}

.subheader {
  @extend .grv-text__body--normal;
  margin-bottom: 16px;
}

.pinnable.pin-to-bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: grv-spacing(small);
  width: calc(100% - 2 * #{grv-spacing(medium)});
}

.pinnable {
  display: block;
  min-width: 0;
  width: calc(100% - 2 * #{grv-spacing(medium)});
  margin: 0 grv-spacing(medium) grv-spacing(small) grv-spacing(medium);
}
