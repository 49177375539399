.progress-button {
  @include grv-button('progressive');
  display: block;
  min-width: 0;
  width: 100%;
  margin: 0 0 grv-spacing(small);
}

.action-button {
  @include grv-button('action');
  display: block;
  min-width: 0;
  width: 100%;
  margin: grv-spacing(medium) 0;
}
