.account-list {
  padding: 0;
  width: 95%;
  margin: 1em 0 24px;
}

.account-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
